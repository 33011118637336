import * as React from "react"
import { Box, Flex, Text, Image } from "theme-ui"
import headingImage from "../images/img16.svg"
import { graphql } from "gatsby"
import auditReportIcon from "../images/audit-report-icon.svg"
import {
  PaddedContent,
  PageHeading,
  PageHeadingParagraph,
  PageWrapper,
} from "../components/modern/layout"

export const query = graphql`
  query QueryAudits {
    allAuditsYaml {
      nodes {
        audits {
          client
          date(formatString: "MMMM YYYY")
          product {
            name
            link
            description
            category
            technologies
          }
          report {
            url
          }
        }
        clients {
          link
          name
          slug
          logo {
            publicURL
          }
        }
      }
    }
  }
`

type Client = {
  slug: string
  name: string
  link: string
  logo: { publicURL: string }
}

type Audit = {
  product: {
    name: string
    link: string
    description: string
    category: string
    technologies: string[]
  }
  client: string
  date: string
  report: {
    url: string
  }
}

const ClientCard: React.FC<{ client: Client }> = ({ client }) => (
  <a href={client.link}>
    <Box
      sx={{
        width: `12rem`,
        textAlign: `center`,
      }}
    >
      <Image src={client.logo.publicURL} alt={client.name} />
    </Box>
  </a>
)

const ClientShowcase: React.FC<{ clients: Client[] }> = ({ clients }) => (
  <Flex
    sx={{
      alignItems: `center`,
      flexWrap: `wrap`,
      columnGap: `5rem`,
      rowGap: `2rem`,
      justifyContent: `center`,
    }}
  >
    {clients.map((client: Client) => (
      <ClientCard key={client.slug} client={client} />
    ))}
  </Flex>
)

const AuditCard: React.FC<{ audit: Audit; client: Client }> = ({
  audit,
  client,
}) => (
  <Box>
    <Flex
      sx={{
        flexWrap: `wrap`,
        marginY: `2em`,
        padding: `2em`,
        gap: `1.3em`,
        alignItems: `center`,
      }}
    >
      {/* Date
        Fixed size
      */}
      <Box
        sx={{
          flexGrow: `0`,
          flexShrink: `0`,
          width: `8em`,
          textAlign: `right`,
        }}
      >
        <Text>{audit.date}</Text>
      </Box>

      {/* Product name and client
        Fixed size
      */}
      <Flex
        sx={{
          marginLeft: `4em`,
          width: `17em`,
          flexDirection: `column`,
          flexGrow: `0`,
          flexShrink: `0`,
        }}
      >
        <Box
          sx={{
            fontVariantCaps: `small-caps`,
            fontSize: `2em`,
          }}
        >
          <a href={audit.product.link}>
            <Text>{audit.product.name}</Text>
          </a>
        </Box>
        <Box
          sx={{
            fontVariantCaps: `small-caps`,
            fontSize: `1.2em`,
          }}
        >
          <Text>
            by{` `}
            <a href={client.link}>{client.name}</a>
          </Text>
        </Box>
      </Flex>

      <Flex
        sx={{
          flexGrow: `1`,
          flexShrink: `1`,
          maxWidth: `50em`,
          flexBasis: `25em`,
          gap: `2.5em`,
          flexWrap: `nowrap`,
          alignItems: `center`,
        }}
      >
        {/* Description and keywords */}
        <Flex
          sx={{
            flexGrow: `1`,
            flexShrink: `1`,
            textAlign: `justify`,
            flexDirection: `column`,
            gap: `1.5em`,
          }}
        >
          <Box>
            <Text
              sx={{
                "&::before": {
                  content: `"“"`,
                },
                "&::after": {
                  content: `"”"`,
                },
              }}
            >
              {audit.product.description}
            </Text>
          </Box>
          <Flex
            sx={{
              flexWrap: `wrap`,
              columnGap: `2em`,
              rowGap: `1em`,
              fontVariantCaps: `small-caps`,
            }}
          >
            <Box
              sx={{
                fontWeight: `bold`,
                paddingX: `.5em`,
                paddingY: `.1em`,
                borderRadius: `1em`,
              }}
            >
              <Text>{audit.product.category}</Text>
            </Box>
            {audit.product.technologies.map((techno, index) => (
              <Box
                key={index}
                sx={{
                  paddingX: `.5em`,
                  paddingY: `.1em`,
                }}
              >
                <Text>{techno}</Text>
              </Box>
            ))}
          </Flex>
        </Flex>
        {audit.report ? (
          <a href={audit.report.url}>
            <Box
              sx={{
                flexGrow: `0`,
                flexShrink: `0`,
                backgroundImage: `url(${auditReportIcon})`,
                backgroundSize: `auto 2.5rem`,
                backgroundRepeat: `no-repeat`,
                backgroundPosition: `top`,
                paddingTop: `3em`,
                alignItems: `center`,
                fontSize: `.8em`,
                textTransform: `uppercase`,
                color: `var(--fg-color)`,
              }}
            >
              <Text>Report</Text>
            </Box>
          </a>
        ) : null}
      </Flex>
    </Flex>
  </Box>
)

const Audits = ({ data }) => {
  const { clients, audits } = data.allAuditsYaml.nodes[0]

  return (
    <PageWrapper title="Audits" pathname="/audits">
      <PageHeading title="Audits" imageSrc={headingImage}>
        <PageHeadingParagraph>
          At Tweag, the High Assurance Software group conducts security audits
          of critical products in domains such as fintech. Here are some clients
          who have trusted us and the products we have audited.
        </PageHeadingParagraph>
      </PageHeading>
      <PaddedContent>
        <ClientShowcase clients={clients} />
      </PaddedContent>
      <PaddedContent>
        <Box>
          <Flex
            sx={{
              flexDirection: `column`,
              fontSize: [`1.1rem`, `1.2rem`],
            }}
          >
            {audits.map((audit: Audit, index: number) => {
              const client = clients.find((c: Client) => c.slug == audit.client)
              return client ? (
                <AuditCard
                  key={index}
                  audit={audit}
                  index={index}
                  client={client}
                />
              ) : (
                console.log(`No matching client found for audit.`, audit)
              )
            })}
          </Flex>
        </Box>
      </PaddedContent>
    </PageWrapper>
  )
}

export default Audits
